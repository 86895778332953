import './index.scss'
import {mapGetters} from "vuex";

export default {
  name: "Search",
  props: {
    renderOption: {type: Array, required: true},
    props: {type: Object},
    model: {type: Object},
    rules: {type: Object},
    splice: {type: Array}
  },
  data() {
    return {
      number: {}
    }
  },
  render() {
    return (
      <el-form
        props={{model: this.model, rules: this.rules, validateOnRuleChange: false, ...(this.props), ...this.$attrs}}
        on={{...this.$listeners}}
        ref={"form"}
        validateOnRuleChange={false}
        class={'jsx_search_wrapper'}
      >
        { this.handleSplice(this.splice) }
      </el-form>
    )
  },
  computed:{
    ...mapGetters({
      houseTreeOption:"charge/houseTreeOption",
    })
  },
  watch: {
    model: {
      immediate: true,
      handler: function () {
        let that = this
        let numberList = that.renderOption.filter((item) => item.type === 'number')
        numberList.forEach((item) => {
          // 设定number 的代替
          if(!isNaN(Number(that.model[item.prop])) && that.model[item.prop] !== '' && that.model[item.prop] !== null){
            that.$set(this.number, item.prop, Number(that.model[item.prop]).toFixed(item.precision || 0));
          } else {
            that.$set(this.number, item.prop, that.model[item.prop]);
          }
        })
      }
    },
    renderOption: {
      immediate: true,
      handler: function () {
        let that = this
        let numberList = that.renderOption.filter((item) => item.type === 'number')
        numberList.forEach((item) => {
          // 设定number 的代替
          if(!isNaN(Number(that.model[item.prop])) && that.model[item.prop] !== '' && that.model[item.prop] !== null){
            that.$set(this.number, item.prop, Number(that.model[item.prop]).toFixed(item.precision || 0));
          } else {
            that.$set(this.number, item.prop, that.model[item.prop]);
          }
        })
      }
    }
  },
  methods: {
    // 切割不同列
    handleSplice(splice) {
      if(splice && splice.length > 0) {
        let AllOption = [...this.renderOption]
        let option = []
        for(let i = 0; i < splice.length + 1; i++) {
          if(splice[i] && AllOption.length > 0){
            option.push(AllOption?.splice(0, splice[i]))
          } else {
            option.push(AllOption)
          }
        }
        return (
          <div class={"search_wrapper"}>
            {
              option?.map((column) => {
                return  (<div class={"search_split_wrapper"}
                              style={{
                                paddingRight: this.props.paddingRight,
                                paddingLeft: this.props.paddingLeft
                              }}>
                  { this.handleRenderList(column) }
                </div>)
              })
            }
          </div>
        )
      } else {
        return (
          <div class={"search_split_wrapper"}>
            { this.renderOption && this.handleRenderList(this.renderOption) }
          </div>
        )
      }
    },

    // 判断type并渲染对应
    handleRenderList(renderList) {
      let itemElement = []
      const typeToRender = (type, item) => {
        switch (type) {
          case 'input': itemElement.push(this.renderInput(item))
            break
          case 'number': itemElement.push(this.renderNumber(item))
            break
          case 'date': itemElement.push(this.renderDate(item))
            break
          case 'search': itemElement.push(this.renderSearch(item))
            break
          case 'time': itemElement.push(this.renderTime(item))
            break
          case 'button': itemElement.push(this.renderButton(item))
            break
          case 'slot': itemElement.push(this.renderSlot(item))
            break
          case 'select': itemElement.push(this.renderSelect(item))
            break
          case 'area': itemElement.push(this.renderArea(item))
            break
        }
      }
      renderList.forEach(({type, ...item}) => {
        typeToRender(type, item)
      })
      return itemElement
    },

    // 渲染输入框
    renderInput({label, prop, props, placeholder, on, width='136px',clearable=true,  ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute}>
          <el-input v-model={this.model[prop]} on={{...on}} style={{width: width}}
                    clearable={clearable}
                    placeholder={placeholder} size={'small'} props={{...props}} {...props}/>
          { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染数字
    renderNumber({label, prop, props, on, placeholder, precision = 0,clearable=true, ...Attribute}) {
      let that = this
      const handleChange = (newValue) => {
        if(!isNaN(Number(newValue)) && newValue !== '' && newValue !== null) {
          that.model[prop] = Number(newValue)
        } else if(newValue === '' || newValue === null) {
          that.model[prop] = newValue
        }
        if(!isNaN(Number(newValue)) || newValue.charAt(newValue.length-1) === '.') {
          that.number[prop] = newValue
        }
      }
      const handleBlur = () => {
        let num = that.model[prop] && Number(that.model[prop]).toFixed(precision)
        if(that.model[prop] !== '' && that.model[prop] !== null){
          that.number[prop] = num
          that.model[prop] = Number(num)
        }
      }
      return (
          <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute}>
            <el-input value={that.number[prop] } placeholder={placeholder}
                      onInput={ handleChange } onBlur={ handleBlur }
                      on={{...on}} clearable={clearable}
                      size={'small'} props={{...props}} {...props}/>
            { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
          </el-form-item>
      )
    },
    // 渲染不同类型输入框
    renderType({label, prop, props: {maxlength, showWordLimit, ...props }, on, placeholder, ...Attribute}, type) {
      return (
        <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute}>
          <el-input v-model={this.model[prop]} type={type}
                    placeholder={placeholder} size={'small'} on={{...on}}
                    maxlength={maxlength} showWordLimit={showWordLimit}
                    props={{...props}} {...props}/>
          { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染日期选择
    renderDate({label, prop, props, on, placeholder,
                 format="yyyy-MM-dd HH:mm:ss",
                 width='136px', ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } props={{...Attribute}} on={{...on}} {...Attribute}>
          <el-date-picker v-model={this.model[prop]} placeholder={placeholder}
                          value-format={format}
                          size={'small'} style={{width: width}} props={{...props}} {...props} />
          { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染日期选择
    renderTime({label, prop, props, on, placeholder,
                 format="yyyy-MM-dd HH:mm:ss",
                  dateType='datetimerange',
                 width='360px', ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } props={{...Attribute}} {...Attribute}>
          <el-date-picker v-model={this.model[prop]} placeholder={placeholder}
                          value-format={format} type={dateType} on={{...on}}
                          start-placeholder={'开始日期'} end-placeholder={"结束日期"}
                          size={'small'} style={{width: width}} props={{...props}} {...props}/>
          { Attribute.sign && <span class={'inputSpan'}>{ Attribute.sign }</span>}
        </el-form-item>
      )
    },

    // 渲染插槽
    renderSlot({label, prop, slotName, ...Attribute}) {
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}}>
          {this.$slots[slotName]}
        </el-form-item>
      )
    },

    // 渲染button
    renderButton({button, float='right',width='136px', ...Attribute}) {
      const reactForm = () => {
        this.resetFields()
      }
      return (
        <div {...Attribute} class={`search-button-${float}`} style={{minWidth: width}}>
          {
            button?.map((item) => {
              let { text, onClick,prop='value', props, ...Attribute } = item
              if(text === '查询') {
                return ( <ch-button
                  key={text} type={'ok'} props={{...props}}
                  onClick={() => onClick()}
                  {...Attribute} {...props}> {text}
                </ch-button>)
              }
              if(text === '重置') {
                return ( <ch-button
                  key={text} type={'cancel'} props={{...props}}
                  onClick={() => {
                    this.model[prop] = null
                    reactForm();
                    onClick?.()
                  }}
                  {...Attribute} {...props}> {text}
                </ch-button>)
              }

              return ( <ch-button
                key={text} props={{...props}}
                onClick={() => onClick()}
                { ...props }{ ...Attribute }>{text}
              </ch-button>)
            })
          }
        </div>
      )
    },

    // 渲染选择框
    renderSelect({ label, prop, name, on, value, key, props, placeholder, option,clearable=true, width='136px', ...Attribute }) {
      key = key ? key:value
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}}>
          <el-select
            class={'input_width'} size={'small'} style={{width: width}}
            placeholder={placeholder}
            on={{...on}}
            clearable={clearable}
            v-model={ this.model[prop] }
            {...props}
            props={{ ...props }} >
            {
              option?.map(( item ) => (
                <el-option
                  key={ item[key] }
                  label={ item[name] }
                  value={ item[value] }
                />
              ))
            }
          </el-select>
        </el-form-item>
      )
    },

    // 渲染区域列表
    renderArea({label, prop, ids=[], option, multiple=true, placeholder,
                 width='136px', backSave, clearable=false,  ...Attribute}) {
      const handleBackSave = (value) => {
        let showValue = null
        let ids = []
        if (multiple === false) {
          // 单选 拼接回显
          showValue = `${value?.areaName?value?.areaName+'-':''}${value.building||''}-${value.roomNumber||''} ${value.userName||''}`
          ids = [value.housingId]
        } else if (multiple === true) {
          // 多选 拼接回显
          let valueNameList = []
          value.forEach((item) => {
            let itemValue
            itemValue = `${item?.areaName?item?.areaName+'-':''}${item.building||''}-${item.roomNumber||''} ${item.userName||''}`
            valueNameList.push(itemValue);
          });
          showValue = valueNameList.join(",");
          ids.push(value.housingId)
        }
        backSave(value, showValue, ids)
      }
      // 打开区域模态框
      const changHouse = () => {
        this.$refs?.house?.show(ids);
        if(!option){
          let timer = null
          let num = 0
          timer = setInterval(()=>{
            num++
            if(this.houseTreeOption){
              option = this.houseTreeOption
              this.$refs?.house?.show(ids);
              clearInterval(timer);
            }
            if(num>=3){
              clearInterval(timer);
            }
          },500)
        }
      }
      // 清除数据
      const handleClear = () => {
        backSave(null, null, [])
      }
      return (
        <el-form-item label={ label } prop={ prop } {...Attribute} props={{...Attribute}}>
          <div>
            <ch-select-house-list
              ref={'house'}
              houseList={option}
              multiple={multiple}
              on-on-back={handleBackSave}
            />
            <el-input
              size="small"
              style={{width: width}}
              v-model={this.model[prop]}
              readonly
              placeholder={placeholder}
              on={{focus: changHouse}}
            ><ch-icon name={"down"} class={"down_img"} slot={"suffix"} onClick={changHouse}/>
            </el-input>
            { clearable && <ch-button class={"clear-button"} type={'link'} onClick={handleClear}>清除</ch-button>}
          </div>
        </el-form-item>
      )
    },

    // 渲染模糊查询选择字段
    renderSearch({ key, value, on, event, props, option, clearable=true, width='136px',keyWidth='106px',
                   valueOption=null, valueValue='id', valueName='name',remoteMethod ,...Attribute }) {
      return (
        <el-form-item prop={key} label-width={'0px'} props={{...Attribute}}>
          <div class={'render-search-wrapper'}>
            <div class={'select-wrapper'}>
              <el-select
                className={'input_width'} size={'small'} style={{width: keyWidth}} placeholder={'请选择'}
                on={{...on}} clearable={clearable} v-model={this.model[key]}{...props} props={{...props}}>
                {option?.map((item) => (
                  <el-option key={item.key} label={item.value} value={item.key}/>
                ))}
              </el-select>
            </div>
            {
              valueOption === null ?
                <div class={'input-wrapper'}>
                  <el-input v-model={this.model[value]} style={{width: width}}
                            clearable={clearable}
                            placeholder={'请输入'} size={'small'} props={{...props}} {...props}/>
                  {Attribute.sign && <span class={'inputSpan'}>{Attribute.sign}</span>}
                </div>
                :
                <div class={'input-wrapper'}>
                  <el-select size={'small'} style={{width: width}}
                    clearable={clearable} placeholder={'请选择'}
                    v-model={this.model[value]} remoteMethod={remoteMethod}
                    filterable={true} remote={true}
                    {...props} on={{...event}} props={{...props}}>
                    {
                      valueOption?.map((item) => (
                        <el-option
                          key={item[valueValue]}
                          label={item[valueName]}
                          value={item[valueValue]}
                        />
                      ))
                    }
                  </el-select>
                </div>

            }
          </div>
        </el-form-item>
      )
    },


    // 重置Form表单数据
    resetFields(){
      this.$refs.form?.resetFields()
    },

    //获取ref
    getRef(){
      return this.$refs.form;
    }

  }
}