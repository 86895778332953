import Vue from 'vue'
import Prompt from './Prompt.vue'


let toastVue

function createPrompt({type="success", title="提示", text="提示内容", showMask=true}) {
  const vnode = new Vue({
    render: h => h(Prompt, {
      props: {type, title, text, showMask}
    })
  }).$mount()
  // 手动 将 生成的对应 dom 插进 body 里面
  document.body.appendChild(vnode.$el)
  // 返回当前实例  的 vue 对象
  return vnode.$children[0]
}



export function showPrompt(args, callback) {
  // 为了让当前的实例 只有一个，防止占用太多内存
  if (toastVue) {
    toastVue.hidePrompt()
    destoryPrompt()
  }
  toastVue = createPrompt(args)
  toastVue.showPrompt()
  callback && callback()
  return toastVue
}

export function hidePrompt() {
  if (!toastVue) return
  toastVue.hidePrompt()
  return toastVue
}

export function destoryPrompt() {
  if (!toastVue) return
  toastVue.destory()
}

export default {
  showPrompt, hidePrompt, destoryPrompt
}
