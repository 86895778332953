<template>
  <!-- 函数弹出弹窗 -->
  <div class="toast" v-if="show">
    <div class="mask" v-if="showMask"></div>
    <div class="message">
      <div class="icon" @click="hidePrompt"><i class="el-icon-close"></i></div>
      <el-result :icon="type" :title="title" :subTitle="text">
        <template slot="extra">
          <ch-button type="cancel" @click="hidePrompt">知道了</ch-button>
        </template>
      </el-result>
    </div>
  </div>

</template>

<script>
import router from "@/route/router";

export default {
  props: {
    type: {
      type: String,
      default: "success"
    },
    title: {
      type: String,
      default: "提示"
    },
    text: {
      type: String,
      default: "提示内容"
    },
    showMask: {
      type: Boolean,
      default: true
    },
    routerName: {
      type: String,
      default: '/login'
    }
  },

  data() {
    return {
      show: false
    }
  },
  methods: {
    showPrompt() {
      this.show = true
    },
    hidePrompt() {
      this.show = false
      router.replace(this.routerName)
    },
    destory() {
      this.$destroy()
    }
  }
}
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2999;
}
.message {
  color: white;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  z-index: 3001;
  .icon {
    color: #CCCCCC;
    font-size: 20px;
    position: absolute;
    right: 16px;
    top: 12px;
  }
}
::v-deep .el-result {
  min-width: 500px;
  padding: 36px 100px 10px;
  .el-result__icon {
    svg {
      width: 80px;
      height: 80px;
    }
  }
  .el-result__title {
    font-weight: 700;
  }
  .el-result__subtitle {
    padding: 10px;
  }
}
</style>